.select_room {
  font-size: 1.5rem;
  width: 100px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: relative;

  &__text__single-value {
    padding-left: 0.5rem !important;
    background-color: #fff !important;
  }

  &__text__menu__menu {
    // z-index: 99999 !important;
    background-color: #fff !important;
  }
}
