.react-date-picker {
  &__box {
    height: 3rem;
    width: 150px;
  }

  &__wrapper {
    padding: 0 1rem;
    border-radius: 5px;
  }
}
